import { Main_Text_Loader } from "monica-alexandria";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from '../../localization/i18next';
import store from '../../redux/store';
import { get_my_subscriptions } from "../../services/api/Users";
const clients = require('../../config/index').config

export default function SUCCESS_PAYMENT() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [safeToRedirect, setSafeToRedirect] = useState(false);
    const lang = i18n.language ? i18n.language : 'en';
    const userId = store.getState()?.user?.user?.sub;

    /* Validate Tool Ownership */
    const checkSubscriptions = () => {
        get_my_subscriptions(userId)
            .then((res) => {
                console.log('lois subs', res?.data);
                let lois_subs = res?.data.find((sub) => sub?.category === 'lois');
                if (lois_subs?.tools?.length > 0 && lois_subs?.tools?.every(tool => tool?.active)) {
                        setSafeToRedirect(true);
                        window.location.assign(`${clients['lois']}/${lang}/welcome`);
                } else {
                    // Retry after a delay if subscriptions are empty
                    setTimeout(checkSubscriptions, 3000); // Retry after 5 seconds
                }
            })
            .catch((err) => {
                console.log('error', err);
                // Retry after a delay in case of error
                setSafeToRedirect(false);
                setTimeout(checkSubscriptions, 3000); // Retry after 5 seconds
            });
    };

    useEffect(() => {
        if (!safeToRedirect) {
            checkSubscriptions();
        }
    }, [safeToRedirect]);

    console.log('safeToRedirect', safeToRedirect);

    return (
        <Main_Text_Loader text={t("Thank you for your trust. We are verifying your subscription.")}/>
    );
}
